<template>
  <turbo-frame id="student_view_preview" ref="frame" :src="frameUrl" />
</template>

<script setup>
import { inject, computed, watch, ref } from 'vue'

const props = defineProps({
  frameUrl: {
    type: String,
    required: true
  }
})

const frame = ref(null)
const currentSlide = inject('currentSlide')

const lastRenderedAt = computed(() => currentSlide.value.serialized_at)

watch(lastRenderedAt, () => {
  frame.value.src = props.frameUrl
})
</script>
