import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    items: { type: Array, default: [] }
  }

  connect() {
    if (this.element.dataset.initialized) return

    if (!this.element.parentElement.classList.contains("array-input-wrapper")) {
      this.setupInput()
    }
    this.loadInitialValues()

    this.element.addEventListener("keydown", this.handleKeydown.bind(this))

    this.element.dataset.initialized = "true"
  }

  setupInput() {
    const wrapper = document.createElement("div")
    wrapper.className = "array-input-wrapper relative h-fit"
    this.element.parentNode.insertBefore(wrapper, this.element)
    wrapper.appendChild(this.element)

    this.pillsContainer = document.createElement("div")
    this.pillsContainer.className = "absolute left-0 top-0 bottom-0 flex flex-wrap gap-1 p-1"
    wrapper.appendChild(this.pillsContainer)

    this.hiddenInput = document.createElement("input")
    this.hiddenInput.type = "hidden"
    this.hiddenInput.name = this.element.name
    wrapper.appendChild(this.hiddenInput)

    this.element.style.paddingLeft = "0.5rem"
    this.element.setAttribute("placeholder", "Type and press Enter, comma, or space to add")

    this.element.name = ""
  }

  loadInitialValues() {
    const rawValue = this.element.value

    if (!rawValue) {
      this.itemsValue = []
      return
    }

    try {
      const parsedValue = JSON.parse(rawValue)

      this.itemsValue = parsedValue.filter(item => item && item.trim())
      this.element.value = ""
      this.updateHiddenInput()
      this.renderItems()
    } catch (e) {
      console.error("Error parsing initial values:", e)
      this.itemsValue = []
    }
  }

  handleKeydown(event) {
    if ([",", "Enter", " "].includes(event.key)) {
      event.preventDefault()
      this.addItem()
    } else if (event.key === "Backspace" && this.element.value === "" && this.itemsValue.length > 0) {
      event.preventDefault()
      this.removeLastItem()
    }
  }

  addItem() {
    const value = this.element.value.trim()
    if (value && !this.itemsValue.includes(value)) {
      this.itemsValue = [...this.itemsValue, value]
      this.element.value = ""
      this.renderItems()
      this.updateHiddenInput()
    }
  }

  removeLastItem() {
    if (this.itemsValue.length > 0) {
      this.itemsValue = this.itemsValue.slice(0, -1)
      this.renderItems()
      this.updateHiddenInput()
    }
  }

  renderItems() {
    this.pillsContainer.innerHTML = this.itemsValue.map(item => `
      <span class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
        ${item}
      </span>
    `).join("")

    requestAnimationFrame(() => {
      const pillsWidth = this.pillsContainer.offsetWidth
      this.element.style.paddingLeft = `${pillsWidth + 8}px`
    })
  }

  updateHiddenInput() {
    // Rails expects an array parameter to be submitted with [] in the name
    if (!this.hiddenInput.name.endsWith('[]')) {
      this.hiddenInput.name = this.hiddenInput.name + '[]'
    }

    const existingInputs = Array.from(this.element.parentElement.querySelectorAll(`input[name="${this.hiddenInput.name}"]`))

    if (this.itemsValue.length === 0) {
      existingInputs.slice(1).forEach(input => input.remove())
      this.hiddenInput.value = ''
    } else {
      existingInputs.slice(this.itemsValue.length).forEach(input => input.remove())

      this.itemsValue.forEach((value, index) => {
        let input = existingInputs[index]
        if (!input) {
          input = this.hiddenInput.cloneNode(true)
          this.element.parentElement.appendChild(input)
        }
        input.value = value
      })
    }
  }
}
