<template>
  <div
    class="h-full bg-white shadow-lg ease-in-out transition-all duration-300 side-panel relative"
    :style="activePanel ? `width: ${activePanel.content.width || '384px'};` : 'width: 0px;'"
  >
    <div v-if="activePanel" class="p-4 relative h-full overflow-y-auto">
      <button
        v-if="showCloseButton"
        @click="closePanel"
        class="absolute top-0 right-0 text-gray-500 hover:text-gray-700 p-4"
      >
        <PlusIcon class="h-6 w-6 transform rotate-45" />
      </button>

      <h2 class="mb-4" :class="activePanel.content.titleClass || 'text-lg font-semibold'">
        {{ activePanel.content.title }}
      </h2>
      <component :is="panelComponent" v-bind="activePanel.content.props" />
    </div>
  </div>
</template>

<script setup>
import { computed, inject, watch } from 'vue'
import { SIDE_PANEL_COMPONENTS } from '@/features/slides_editor/components_directory'
import { PlusIcon } from '@heroicons/vue/24/outline'
import { useSidePanelHelpers } from '@/features/slides_editor/composables/useSidePanelHelpers'

const { stack, activePanel, popFromStack } = useSidePanelHelpers()
const reveal = inject('reveal')

const showCloseButton = computed(() => {
  if (!activePanel) {
    return false
  }

  if (stack.value.length == 1 && activePanel.value.fixed) {
    return false
  }

  if (!activePanel.value.shared && !activePanel.value.fixed) {
    return true
  }

  return activePanel.value.shared
})

const panelComponent = computed(() => {
  return SIDE_PANEL_COMPONENTS[activePanel.value.content.component]
})

const closePanel = () => {
  popFromStack()
}

watch(() => activePanel.value, (newPanel, oldPanel) => {
  if (newPanel !== oldPanel) {
      setTimeout(() => {
        reveal.value.layout()
      }, 300)
  }
}, { deep: true })
</script>
