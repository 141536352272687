<template>
  <div class="grid grid-cols-2 gap-2">
    <div v-for="(variant) in questionVariants"
      @click="onAddQuestion(variant.key)"
      :key="variant.key"
      :class="[variant.background_color, variant.text_color, variant.border_color, variant.border_hover_color]"
      class="rounded-lg border p-6 pt-8 aspect-square cursor-pointer flex flex-col flex-1 justify-start items-center gap-4" href="/schools/1/classes/new">
      <div class="w-10 h-10 flex items-center justify-center rounded-lg border" :class="[variant.icon_background_color, variant.border_color]">
        <component v-if="variant.icon" :is="icons[variant.icon]" class="w-6 h-6" />
      </div>
      <div class="flex flex-col gap-2 text-center">
        <span class="text-base font-normal">
          Add {{ variant.label }} question
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import * as icons from "@heroicons/vue/24/outline"
import { QUESTION_VARIANTS } from '@/features/slides_editor/config/constants/question_variants'
import { useQuestionHelpers } from '@/features/slides_editor/composables'
import { useSidePanelHelpers } from '@/features/slides_editor/composables/useSidePanelHelpers'

const slide = inject('currentSlide')

const { hydrateNewQuestion } = useQuestionHelpers()
const { pushToStack } = useSidePanelHelpers()
const questionVariants = computed(() => {
  return Object.entries(QUESTION_VARIANTS).filter(([key, variant]) => !variant.disabled).map(([key, variant]) => {
    return {
      ...variant,
      key
    }
  })
})

const onAddQuestion = (variant) => {
  const block = slide.value.blocks.find(block => block.block_type === 'section')

  const newQuestion = {...hydrateNewQuestion(variant, block)}

  block.section.questions.push(newQuestion)

  setTimeout(() => {
    const container = document.querySelector('.present [data-scrollable-container]')
    container.scrollTop = container.scrollHeight
  }, 100)

  pushToStack({
    content: {
      component: 'QuestionForm',
      title: 'Add Question',
      props: {
        question: newQuestion,
        block: block,
        isNew: true
      }
    }
  })
}
</script>
