import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'list', 'option', 'input',
    'pill', 'pills', 'search',
    'toggleContainer', 'formOn', 'formOff',
    'createOption', 'inputTemplate', 'pillTemplate',
    'optionTemplate', 'default'
  ]
  // options is an array of arrays of [name, id]
  // selected is an array of arrays of [name, id]
  static values = {
    options: Array,
    selected: Array,
    useId: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  }

  initialize() {
    this.addedOptions = []

    this.onClickAway()
    this.toggleRequired()

    this.SEARCH_INPUT_PREFIX = 'search_input'
  }

  onClickAway() {
    document.addEventListener('click', (e) => {
      const withinBoundaries = e.composedPath().includes(this.listTarget)

      if (withinBoundaries || e.target === this.searchTarget) {
        return
      }

      this.listTarget.classList.add('hidden')
    })
  }

  createListItem([name, id]) {
    id = id.toString().replace(/\s/g, '_')
    const template = this.optionTemplateTarget.cloneNode(true)

    template.content.querySelector('li').setAttribute('data-id', `search_input_${id}`)
    template.content.querySelector('[data-input-value]').innerText = name

    return template
  }

  open() {
    if (this.searchTarget.readOnly) {
      return
    }

    const query = this.searchTarget.value

    const matching = this.optionsValue
      .filter(option => !this.addedOptions.includes(option[1].toString()))

    if (matching.length) {
      const templates = matching.map(match => this.createListItem(match))

      if (this.hasCreateOptionTarget && query.length && !this.optionsValue.find(opt => opt[1].toLowerCase() === query.toLowerCase())) {
        this.createOptionTarget.content.querySelector('[data-input-value]').innerText = `Add "${query}"`
        templates.push(this.createOptionTarget)
      }

      this.listTarget.innerHTML = templates.map(t => t.innerHTML).join('')

      this.listTarget.classList.remove('hidden')
    } else if (this.hasCreateOptionTarget && query.length && !this.optionsValue.find(opt => opt[0].toLowerCase() === query.toLowerCase())) {
      this.createOptionTarget.content.querySelector('[data-input-value]').innerText = `Add "${query}"`
      this.listTarget.innerHTML = this.createOptionTarget.innerHTML

      this.listTarget.classList.remove('hidden')
    } else {
      this.listTarget.classList.add('hidden')
    }
  }

  toggleForm(e) {
    this.toggleContainerTarget.classList.toggle('hidden')

    const button = e.currentTarget

    const newToggleText = button.innerText
    const newToggleClasses = button.className

    if (this.toggleContainerTarget.classList.contains('hidden')) {
      this.toggleContainerTarget.querySelectorAll('[data-search-pill]').forEach(pill => pill.remove())
      this.toggleContainerTarget.querySelectorAll('[data-search-input]').forEach(input => input.remove())
      this.addedOptions = []
      this.formOnTarget.classList.remove('hidden')
    } else {
      this.formOnTarget.classList.add('hidden')
    }
  }

  search(e) {
    const query = e.target.value

    if (!query) {
      this.listTarget.classList.add('hidden')
      return
    }

    const matching = this.optionsValue
      .filter(option => option[0].toLowerCase().includes(query.toLowerCase()) && !this.addedOptions.includes(option[1].toString().toLowerCase()))

    if (matching.length) {
      const templates = matching.map(match => this.createListItem(match))

      if (this.hasCreateOptionTarget && !this.optionsValue.find(opt => opt[0].toLowerCase() === query.toLowerCase())) {
        this.createOptionTarget.content.querySelector('[data-input-value]').innerText = `Add "${query}"`
        templates.push(this.createOptionTarget)
      }

      this.listTarget.innerHTML = templates.map(t => t.innerHTML).join('')

      this.listTarget.classList.remove('hidden')
    } else if (this.hasCreateOptionTarget && !this.optionsValue.find(opt => opt[0].toLowerCase() === query.toLowerCase())) {
      this.createOptionTarget.content.querySelector('[data-input-value]').innerText = `Add "${query}"`
      this.listTarget.innerHTML = this.createOptionTarget.innerHTML

      this.listTarget.classList.remove('hidden')
    } else {
      this.listTarget.classList.add('hidden')
    }
  }

  createOption(query, id) {
    // if query is an event object then set it to undefined
    if (query instanceof Event) {
      query = undefined
    }
    query = query || this.searchTarget.value
    // if id exists, then use only its numbers
    // else id is equal to the query with spaces replaced with underscores

    id = id
      ? id.toString().replace(`${this.SEARCH_INPUT_PREFIX}_`, '')
      : query.replace(/\s/g, '_').toLowerCase()

    this.addInput(id, query)
    this.addPill(id, query)

    this.addedOptions.push(id)

    this.searchTarget.value = ''

    if (this.listTarget.querySelector(`[data-id="${this.SEARCH_INPUT_PREFIX}_${id}"]`)) {
      this.listTarget.querySelector(`[data-id="${this.SEARCH_INPUT_PREFIX}_${id}"]`).remove()
    }
  }

  addInput(id, value) {
    const input = this.inputTemplateTarget.content.querySelector('input')
    input.value = this.useIdValue ? id : value
    input.id = `search_input_${id}`

    if (input.dataset.nestedForm) {
      input.name = input.name.replace(/\[\d+\]/g, `[${this.addedOptions.length + 1}]`)
    }

    this.element.insertAdjacentHTML('beforeend', this.inputTemplateTarget.innerHTML)
  }

  addPill(id, value) {
    this.pillTemplateTarget.content.querySelector('[data-search-pill]').id = `search_pill_${id}`
    this.pillTemplateTarget.content.querySelector('[data-text]').innerText = value
    this.pillTemplateTarget.content.querySelector('[data-search-pill]').dataset.id = id
    this.pillTemplateTarget.content.querySelector('button').setAttribute('data-id', id)

    this.pillsTarget.insertAdjacentHTML('beforeend', this.pillTemplateTarget.innerHTML)
  }

  select(e) {
    this.createOption(e.currentTarget.innerText, e.currentTarget.dataset.id)
    window.dispatchEvent(new CustomEvent('pillinput:selected'))
  }

  remove(e) {
    const id = e.currentTarget.dataset.id

    this.addedOptions = this.addedOptions.filter(item => item !== id)

    document.querySelector(`#search_input_${id}`).remove()
    document.querySelector(`#search_pill_${id}`).remove()

    this.listTarget.classList.add('hidden')
    this.toggleRequired()

    window.dispatchEvent(new CustomEvent('pillinput:removed'))
  }

  toggleRequired() {
    const { required } = this.searchTarget.dataset

    if (!required) {
      return
    }

    if (this.addedOptions.length) {
      this.searchTarget.removeAttribute('required')
    } else {
      this.searchTarget.setAttribute('required', 'required')
    }
  }

  pillTargetConnected(pill) {
    this.addedOptions.push(pill.dataset.id)
    this.toggleRequired()
  }
}
