import { Controller } from '@hotwired/stimulus'
import Uppy, { debugLogger } from '@uppy/core'
import DragDrop from '@uppy/drag-drop'
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload'

export default class extends Controller {
  static values = {
    height: {
      type: String,
      default: "100%"
    },
    maxNumberOfFiles: {
      type: Number,
      default: 1
    },
    allowedFileTypes: {
      type: Array,
      default: []
    },
    urlParams: {
      type: Object,
      default: {}
    },
    required: {
      type: Boolean,
      default: false
    }
  }

  static targets = ['container', 'form', 'input', 'spinner']

  connect() {
    this.initUppy()
  }

  initUppy() {
    this.containerTarget.classList.add("relative")

    this.uppy = new Uppy({
      logger: debugLogger,
      restrictions: {
        allowedFileTypes: this.allowedFileTypesValue,
        maxNumberOfFiles: this.maxNumberOfFilesValue
      }
    })
      .use(DragDrop, {
        target: this.containerTarget,
        height: this.heightValue,
        locale: {
          strings: {
            dropHereOr: '%{browse} or drag and drop',
            browse: 'Upload class template'
          }
        }
      })
      .use(ActiveStorageUpload, {
        directUploadUrl: this.directUploadUrl
      })

    window.uppy = this.uppy

    this.uppy.on('file-added', async _ => {
      this.showLoadingSpinner()

      const result = await this.uppy.upload()

      this.removeLoadingSpinner()

      result.successful.forEach(file => {
        this.updateHiddenInput(file)
        this.renderThumbnail(file)
      })

      result.failed.forEach(file => {
        this.uppy.removeFile(file.id)
      })
    })
  }

  renderThumbnail(file, preview) {
    const div = this.overlayDiv

    const fileEl = `
      <div class="relative current-file">
        <button data-action="uppy#removeFile" class="absolute right-0 top-0 -mr-3 -mt-4 bg-gray-100 rounded-full border border-gray-300 p-1">
          <svg class="w-3 h-3 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <span class="rounded-lg bg-indigo-100 text-indigo-600 px-3 py-1">${file.name}</span>
      </div>
    `

    div.innerHTML = fileEl

    this.containerTarget.appendChild(div)
  }

  updateHiddenInput(file) {
    this.inputTarget.setAttribute('data-pending-upload', true)
    this.inputTarget.value = file.response.signed_id
  }

  removeFile(e) {
    e.target.closest("[data-uppy-overlay]").remove()

    if (this.hasInputTarget) {
      this.inputTarget.value = null
    }

    const files = this.uppy.getFiles()
    files.forEach(file => {
      this.uppy.removeFile(file.id)
    })
  }

  showLoadingSpinner() {
    const div = this.overlayDiv
    const spinner = `
      <svg class="animate-spin w-5 h-5 text-indigo-600" data-uppy-target="spinner" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    `

    div.innerHTML = spinner

    this.containerTarget.appendChild(div)
  }

  removeLoadingSpinner() {
    this.spinnerTarget.parentNode.remove()
  }

  get overlayDiv() {
    const div = document.createElement('div')
    div.setAttribute('data-uppy-overlay', true)
    div.classList.add(
      "uppy-file",
      "absolute",
      "z-200",
      "inset-0",
      "w-100",
      "h-100",
      "bg-white",
      "flex",
      "items-center",
      "justify-center"
    )

    return div
  }

  get directUploadUrl() {
    const path = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
    const url = new URL(`${window.location.origin}${path}`)

    Object.entries(this.urlParamsValue).forEach(([key, value]) => {
      url.searchParams.append(key, value)
    })

    console.log(url.toString())
    return url.toString()
  }
}
